import profileAboutModule from '../profileAboutModule';

const profileDescription = {
  templateUrl: 'profile-description',
  controller: 'profileDescriptionController'
};

profileAboutModule.component('profileDescription', profileDescription);

export default profileDescription;
